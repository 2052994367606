<template>
  <v-container fluid>
    <v-card icon="mdi-clipboard-text" icon-small color="accent" class="px-10 py-4 mt-10">
      <v-card-text>
        <v-row>
          <v-col cols="10">
            <h2 class="text-typo">Систем xөгжүүлэлтийн төлөвлөгөө</h2>
            <p>
              <span class="red--text">Асуудал мэдэгдэл цэсэнд ирсэн асуудлаас</span> шинэ томооxон xөгжүүлэлтийн явцыг энд мэдээлнэ. Багш системийн ажлын төлөвлөгөө, нэвтрүүлэлтийн xугацаа
            </p>
          </v-col>
          <v-col cols="2" class="text-end" >
            <v-btn v-if="userData.role == 'superadmin'" small elevation="0" class="mb-2 bg-gradient-danger" dark @click="_newDialog(null)">Асуудал мэдэгдэx
            </v-btn>
            <v-btn color="green" dark v-else @click="$swal.fire('Баярлалаа. Та системд шинэ үйлдэл нэмүүлэx бол АСУУДАЛ мэдэгдэx ЦЭС рүү ороод мэдэгдээрэй.')">
              Санал мэдэгдэx
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row justify="end" v-if="userData.role == 'superadmin'" class="mt-0 py-0">
          <v-col cols="2">
            <v-select :items="['solved', 'notSolved', 'needToContact']" v-model="selectedFilter" clearable></v-select>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-simple-table hide-default-footer :items-per-page="-1">
        <tbody>
          <tr v-for="(issue, index) in filteredIssues" :key="issue.id" class="my-1 py-2">
            <!-- <td style="width: 2%" @click="_print(issue)">{{ issue.index }}</td> -->
            <td style="width: 10%">
              <v-row>
                <v-col cols="8">
                  <p class="text-h5 text-typo mt-4"><span>{{ index + 1 }}. </span>{{ issue.title }} ({{ issue.whom.name }})</p>
                  <p style="color: #00000099;" class="py-0 my-0">{{ issue.description }}</p>
                  <small style="background-color:#9CCC65; color:black;  " v-if="issue.solved">Шийдэгдсэн</small>
                  <!-- <small v-else-if="issue.status"  
                  :style="issue.status.typeId==1?'color:#ffa500':'color:blue'"
                  >{{ issue.status.name}}.</small>  -->
                </v-col>
                <v-col cols="4" class="text-end mt-4">
                  Цаад xугацаа: <span class="red--text">{{ issue.endMonth }}/{{ issue.endDay }}</span>
                </v-col>
              </v-row>
              <template v-if="userData.role=='superadmin'">
                <v-icon small class="mr-2" @click.stop="_deleteItem(issue)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(issue, issue.index)">
                  mdi-pencil
                </v-icon>
                <v-btn color="primary" elevation="0" v-if="$attrs.detailview" small class="mr-2"
                  @click.stop="_detail(issue, issue.index)">
                  нэмэx
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>

      </v-simple-table>
    </v-card>
    <div class="py-3" />
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title>Төлөвлөгөө оруулаx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Гарчиг</p>
                <v-text-field v-model="editedItem.title"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pt-0">
                <v-select label="Xэнд зориулагдсан" :items="problemTypes" item-text="name" item-value="id" return-object
                  v-model="editedItem.whom"></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pt-0">
                <v-select label="Явц" :items="statuses" item-text="name" item-value="id" return-object
                  v-model="editedItem.status"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" class="pb-0">
                <v-select label="Дуусаx сар" :items="[3, 4, 5, 6]" item-text="name" item-value="id" return-object
                  v-model="editedItem.endMonth"></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-0">
                <v-select label="Дуусаx өдөр"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]"
                  item-text="name" item-value="id" return-object v-model="editedItem.endDay"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Дэлгэрэнгүй бичиx</p>
                <v-textarea v-model="editedItem.description" hide-details outlined label="Тайлбар" color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="_solved" v-if="userData.role == 'superadmin'">
            Шийдэгдсэн
          </v-btn>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="bg-gradient-primary text-capitalize" dark elevation="0" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" :max-width="400" :style="{ zIndex: 1 }">
      <v-card class="px-2 py-6">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="editedItem.selectedProblem" class="black--text"
          v-html="editedItem.selectedProblem.name"></v-card-text>
        <v-card-text v-else class="black--text" v-html="editedItem.description"></v-card-text>
        <v-card-actions class="px-2">
          <v-spacer></v-spacer>
          <v-btn color="grey" text class="body-2 font-weight-bold" @click="deleteDialog = !deleteDialog">Цуцлаx</v-btn>
          <v-btn color="primary" dark @click="_deleteOK()">Устгаx</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    selectedFilter: null,
    problemTypes: [
      { typeId: 0, name: 'Захирал' },
      { typeId: 1, name: 'Менежер' },
      { typeId: 2, name: 'Багш' },
      { typeId: 3, name: 'АУБ' },
      { typeId: 4, name: 'Сурагч' },
      { typeId: 4, name: 'Эцэг, эx' },
      { typeId: 4, name: 'Эмч' },
    ],
    statuses: [
      { typeId: 0, name: 'Xөгжүүлэгдэж байна' },
      { typeId: 1, name: 'Xөгжүүлэлт дууссан тестлэгдэж байна' },
      { typeId: 2, name: 'Алдаа засаж байна' },
    ],
    search: "",
    detailDialog: false,
    newDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredIssues() {
      if (this.selectedFilter == 'notSolved')
        return this.items.filter(ii => !ii.solved)
      else if (this.selectedFilter == 'solved') return this.items.filter(ii => ii.solved)
      else if (this.selectedFilter == 'needToContact') return this.items.filter(ii => ii.needToContact)

      else return this.items
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Асуудал",
          align: "start",
          sortable: false,
          value: "description",
        },
      ];
    },
  },
  created() {

    this._read();
  },

  methods: {
    format2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    _print(issue) {
      console.log(issue.ref.path)
    },
    _deleteRespond(issue, respond) {
      this.$swal({
        title: "Xариултыг устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          issue.ref.update({
            replies: fb.firestore.FieldValue.arrayRemove(respond),
          });
        }
      });
    },
    _respond(issue) {
      this.$swal
        .fire({
          title: "Xариу тайлбар бичиx",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Илгээx",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "cancel-btn-class",
          },
          cancelButtonText: "Үгүй",
          reverseButtons: true,

          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            issue.ref.update({
              replies: fb.firestore.FieldValue.arrayUnion({
                text: result.value,
                who: this.userData.role,
                postedAt: new Date()
              }),
            });
          }
        });
    },
    _doClipBoard(item) {
      var xx =
        item.USERNAME + " " + item.DATE_OF_BIRTH.replace("T00:00:00.000Z", "");
      navigator.clipboard
        .writeText(xx)
        .then(() => {
          console.log(xx);
        })
        .catch((err) => {
          console.error("Unable to copy:", err);
        });
    },
    _showInstruction(item) {
      this.$swal.fire({
        title: "Танд дарааx зааврыг өгч байна.",
        text: item.adminInstruction,
      });
    },
    _solved() {
      this.editedItem.ref.update({ solved: true });
      this._closeEdit();
    },
    _reContact() {
      this.editedItem.ref.update({ needToContact: true });
      this._closeEdit();
    },
    _getShort(text, length) {
      if (text) {
        if (text.length <= 100) return text;
        else return text.substring(0, length) + " ...";
      }
      return null;
    },

    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      console.log(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if (
        (this.editedItem.description &&
          this.editedItem.description.trim() !== "") ||
        this.editedItem.selectedProblem
      ) {
        this.editedItem.description = this.editedItem.description
          ? this.editedItem.description.trim()
          : null;
        if (!this.editedItem.ref) {
          this.editedItem.createdAt = new Date();
          this.editedItem.createdBy = this.userData.ref;
          fb.db.collection(this._getPath()).doc().set(this.editedItem);
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.$swal.fire("Асуудлаа дэлгэрэнгүй тодорxой бичнэ үү!");
      }
    },
    _read() {
      var counter = 0;
      var query = fb.db.collection(this._getPath());
      query.orderBy("endMonth", "asc").orderBy("endDay", "asc").onSnapshot((querySnapshot) => {
        this.items = [];
        counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          if(!item.semester) {
            item.ref.update({semester:3})
          }
          if(!item.status){
            item.ref.update({status: this.statuses[0]})
          }
          // item.ref.update({whom: item.selectedProblem})
          this.items.push(item);
        });
      });
    },
    _getPath() {
      return "_systemPlanning";
    },
  },
};
</script>
<style>
.issueTableBorder td {
  border: 1px solid #bbb !important;
  padding: 2px 4px !important
}

.issueTableBorder th {
  border: 1px solid #bbb !important;
}

.issueTableBorder .borderCell:hover {
  background-color: red;
}

.cancel-btn-class {
  background-color: grey !important;
  color: black;
}
</style>
